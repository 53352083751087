import { orderBy } from 'lodash';
import { Router } from '@angular/router';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Message } from '../../utils/message';
import { LeadModel } from '../../models/lead/lead.model';
import { LeadService } from '../../services/lead/lead.service';
import { SellerModel } from '../../models/sellers/sellers.model';
import { ErrorService } from '../../services/error/error.service';
import { CouponFidelityModel } from '../../models/fidelity/coupon-fidelity.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { ModalLeadOnboardingComponent } from '../modals/modal-lead-onboarding/modal-lead-onboarding.component';
import { FidelityService } from '../../services/fidelity/fidelity.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CouponFiltersModel } from '../../models/coupon/coupon-filters.model';
import { ModalCouponDetailsComponent } from '../modals/modal-coupon-details/modal-coupon-details.component';
import { th } from 'date-fns/locale';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerFiltersCouponComponent } from '../drawers/drawer-filters-coupon/drawer-filters-coupon.component';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.scss'],
})
export class CouponDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('loadMore') loadMore: ElementRef;

  @Input() public mode: string = 'ALL';

  @Input() public reference: string = '';
  @Input() public inactiveCouponsAmount: number = 0;
  @Input() public activeCouponsAmount: number = 0;

  public loadingSearch: boolean = false;

  public selectedOrderByItem: string = 'Mais relevantes';

  public orderByMap = {
    RELEVANCE: 'Mais relevantes',
    PRICE_DESC: 'Menor preço',
    PRICE_ASC: 'Maior preço',
    AVALIATION_DESC: 'Mais avaliados',
    DUE_DATE_DESC: 'Próximos ao vencimento',
  };

  public coupons: Array<CouponFidelityModel> = [];

  public filterData: Array<Partial<CouponFidelityModel>> = [];

  public lead: LeadModel = new LeadModel();

  public seller: SellerModel = new SellerModel();

  public filters: CouponFiltersModel = new CouponFiltersModel();

  public loadValue: number = 10;

  public loadingMore: boolean = false;

  public stopLoading: boolean = false;

  public loading: boolean = true;

  public isMobile: boolean = window.innerWidth < 768;

  public newFilter: string = '';

  public typeCupons: any[] = [
    { label: 'Porcentagem', value: 'PERCENT' },
    { label: 'Valor fixo', value: 'REAL_VALUE' },
  ];

  public categories: any[] = [];

  public previousMallsState: any[];

  public filterFormSide: FormGroup;

  public filterForm: FormGroup;

  public mallForm: FormGroup;

  public searchForm: FormGroup;

  private destroy$ = new Subject();

  public malls: Array<any>;

  public orderBy: string;

  constructor(
    private $lead: LeadService,
    private $error: ErrorService,
    private $fidelity: FidelityService,
    private readonly router: Router,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private $notification: StateManagementService,
    private fb: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.getMallList();

    this.getLead();

    this.getNotification();

    this.getCategory();

    this.createFilterForm();

    this.createSearchForm();
  }

  public createSearchForm(): void {
    this.searchForm = this.fb.group({
      search: this.fb.control(''),
    });

    this.searchFormValueChanges();
  }

  public searchFormValueChanges() {
    this.searchForm
      .get('search')
      ?.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => this.performSearch(value))
      )
      .subscribe((result) => {
        if (result.length > 0) {
          this.filterData = result;
        }
        this.loadingSearch = false;
      });
  }

  public performSearch(value: string): Observable<any[]> {
    this.loadingSearch = true;

    const filteredCoupons = this.coupons.filter((coupon) =>
      coupon.seller.business_name.toLowerCase().includes(value.toLowerCase())
    );

    return of(filteredCoupons);
  }

  public mallFormValueChanges(): void {
    this.mallForm.valueChanges.subscribe((changes) => {
      this.chageMallFilter(changes);
    });
  }

  public filterFormValueChanges(): void {
    this.filterForm.valueChanges.subscribe((changes) => {
      this.getLead();
    });
  }

  private getMallList(): void {
    this.$fidelity.getMallList().subscribe({
      next: (res) => {
        this.malls = res?.data?.malls.results.map((mall) => {
          return {
            label: mall.id,
            value: mall.id,
            checked: false,
          };
        });
        this.createMallForm();
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  private createMallForm(): void {
    this.mallForm = this.fb.group({
      mallListOptions: this.fb.array([]),
    });
    this.initMalls();
  }

  public createFilterForm(): void {
    this.filterForm = this.fb.group({
      mall: this.fb.array([]),
      category: this.fb.array([]),
      typeDiscount: this.fb.array([]),
      orderBy: this.fb.control('RELEVANCE'),
    });
    this.filterFormValueChanges();
  }

  private initMalls(): void {
    const mallForm = this.mallForm.get('mallListOptions') as FormArray;

    this.malls.forEach((mall) => {
      mallForm.push(
        this.fb.group({
          label: [mall.label],
          value: [mall.value],
          checked: [mall.checked],
        })
      );
    });

    this.mallFormValueChanges();
  }

  private getCategory(): void {
    this.$lead.getCategories().subscribe({
      next: (res) => {
        this.categories = res?.data?.allCategoriesCouponLead;
      },
      error: (error) => {
        this.$error.errorHandling(error, Message.ERROR_CONECTION);
      },
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(1);
    this.destroy$.complete();
  }

  private getLead(): void {
    this.$notification.leads.subscribe((lead) => {
      if (lead?.id) {
        this.lead = lead;
      }

      if (this.mode === 'ALL') {
        this.getAllCoupons(lead?.id);
      } else if (this.mode === 'MY_COUPONS') {
        this.getMyCoupons();
      } else if (this.mode === 'CATEGORY') {
        this.getCouponsByCategoryId(lead?.id);
      } else {
        this.getCouponsBySellerId(lead?.id);
      }
    });
  }

  private getNotification(): void {
    this.$notification.filterForms.subscribe((filterForm) => {
      if (filterForm) {
        this.filterForm = filterForm;

        if (this.mode === 'MY_COUPONS') {
          this.getMyCoupons();
        } else if (this.mode === 'CATEGORY') {
          this.getCouponsByCategoryId(this.lead?.id);
        } else if (filterForm?.orderByPriceDesc) {
          this.getCouponsBySellerId();
        } else if (filterForm?.orderByPriceAsc) {
          this.getCouponsBySellerId();
        } else if (filterForm?.orderByRelevance) {
          this.getCouponsBySellerId();
        } else if (filterForm?.orderByDueDateDesc) {
          this.getCouponsBySellerId();
        } else if (filterForm?.orderByAvaliationDesc) {
          this.getCouponsBySellerId();
        }
      }
    });

    this.$notification.formFilters.subscribe((filter => {
      if (filter) {
        this.filterForm = filter;
        this.getLead();
      }

      if (filter === null) {
        this.createFilterForm();
      }
    }))
  }

  private getAllCoupons(leadId?: string): void {
    this.$lead
      .getCouponsByLeadId(leadId, this.getFilterFormValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.couponsLead) {
            this.coupons = JSON.parse(JSON.stringify(res.data.couponsLead));

            this.filterData = this.coupons.slice(0, this.loadValue);

            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;

          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getMyCoupons(): void {
    this.$lead
      .getMyCoupons(this.getFilterFormValue())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.leadRescuedCoupon) {
            this.coupons =
              this.reference === 'ACTIVE'
                ? JSON.parse(JSON.stringify(res.data.leadRescuedCoupon?.actives))
                : JSON.parse(JSON.stringify(res.data.leadRescuedCoupon?.inactives));

            this.filterData = this.coupons.slice(0, this.loadValue);

            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;

          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getCouponsByCategoryId(leadId?: string): void {
    this.$lead
      .getCouponsByCategoryId(leadId, this.reference, this.filterForm, this.orderBy)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.allCategoriesCouponLead) {
            this.coupons = res.data.allCategoriesCouponLead?.[0]?.coupons
              ? JSON.parse(JSON.stringify(res.data.allCategoriesCouponLead?.[0].coupons))
              : [];

            this.filterData = this.coupons.slice(0, this.loadValue);

            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;

          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  private getCouponsBySellerId(leadId: string = null): void {
    this.$lead
      .getCouponsBySellerId(this.reference, leadId, this.filterForm?.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          if (res?.data?.sellerToCoupon) {
            this.seller = JSON.parse(JSON.stringify(res.data.sellerToCoupon));

            this.coupons = this.seller?.coupons ? this.seller.coupons : [];
            this.filterData = this.coupons.slice(0, this.loadValue);

            this.loadingMore = this.coupons.length <= this.loadValue;
          }

          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        error: (error) => {
          this.loading = false;

          this.$error.errorHandling(error, Message.ERROR_CONECTION);
        },
      });
  }

  public useCoupon(coupon: Partial<CouponFidelityModel>): void {
    this.showCouponDetails(coupon.id);
  }

  private showCouponDetails(id: string): void {
    this.$modal.create({
      nzTitle: null,
      nzContent: ModalCouponDetailsComponent,
      nzWidth: window.innerWidth < 1200 ? 520 : 823,
      nzFooter: null,
      nzCentered: true,
      nzBodyStyle: { padding: '0' },
      nzClosable: false,
      nzComponentParams: {
        id: id,
      },
    });
  }

  public addFilter(filter: string): void {
    const appliedFiltersControl = this.filterFormSide.get('appliedFilters');

    if (filter && !appliedFiltersControl.value.includes(filter)) {
      const currentValue = appliedFiltersControl.value.slice();

      currentValue.push(filter);

      appliedFiltersControl.setValue(currentValue);
    }
  }

  public removeAppliedFilter(filter: string): void {
    const value = this.filterFormSide.get('appliedFilters').value.filter((f) => f !== filter);

    this.filterFormSide.get('appliedFilters').setValue(value);
  }

  public likeCoupon(coupon: Partial<CouponFidelityModel>): void {
    if (this.lead.id) {
      if (coupon.isFavorite) {
        this.$lead
          .unlikeCoupon(coupon.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => {
              if (res?.data?.removeLeadLikeCoupon) {
                coupon.isFavorite = !coupon.isFavorite;
              }
            },
            error: (error) => {
              this.$error.errorHandling(
                error,
                'Erro ao retirar o like no cupom. Por favor, tente novamente mais tarde.'
              );
            },
          });
      } else {
        this.$lead
          .likeCoupon(coupon.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res) => {
              if (res?.data?.addLeadLikeCoupon) {
                coupon.isFavorite = !coupon.isFavorite;
              }
            },
            error: (error) => {
              this.$error.errorHandling(error, 'Erro ao dar like no cupom. Por favor, tente novamente mais tarde.');
            },
          });
      }
    } else {
      this.showLoginModal();
    }
  }

  public showMore(): void {
    this.stopLoading = true;
    this.filterData = this.filterData.concat([...Array(10)].fill({}).map(() => ({ loading: true, name: '' })));

    setTimeout(() => {
      this.loadValue += 10;
      this.filterData = this.coupons.slice(0, this.loadValue);
      this.loadingMore = this.loadValue > this.coupons.length;
      this.stopLoading = false;
    }, 1500);
  }

  private showLoginModal(): void {
    this.$modal.create({
      nzTitle: null,
      nzContent: ModalLeadOnboardingComponent,
      nzFooter: null,
      nzCentered: true,
      nzBodyStyle: { padding: '24px' },
      nzClosable: true,
      nzClassName: 'ant-modal-fullscreen',
    });
  }

  public trackByFn(index: number, coupon: Partial<CouponFidelityModel>): string {
    return coupon.id;
  }

  public onOrderByItemSelect(item: string): void {
    this.orderBy = item;
    this.searchForm.get('search').setValue('');
    this.selectedOrderByItem = this.orderByMap[item] || 'Mais relevantes';
    this.filterForm.get('orderBy').setValue(item);
  }

  public shouldLoadMoreItems(): boolean {
    if (this.loadMore?.nativeElement) {
      const element = this.loadMore.nativeElement;
      const elementTop = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const minimumTopMargin = 100;

      return elementTop - minimumTopMargin <= windowHeight;
    }

    return false;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (!this.loadingMore && this.shouldLoadMoreItems() && !this.stopLoading) {
      this.showMore();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.isMobile = event.target.innerWidth < 768;
  }

  public changeCategoryFilter(value: string): void {
    const categoryFilter = this.filterForm.get('category') as FormArray;
    categoryFilter.push(this.fb.control(value));
  }

  public chageTypeCouponFilter(value: string): void {
    const typeCouponFilter = this.filterForm.get('typeDiscount') as FormArray;
    typeCouponFilter.push(this.fb.control(value));
  }

  public chageMallFilter(value: any): void {
    const mallFilter = this.filterForm.get('mall') as FormArray;

    const mallList = value;

    const checkedFields = mallList.mallListOptions.filter((mall) => {
      if (mall.checked) return mall;
    });

    checkedFields.forEach((mall) => {
      const alreadyExist = mallFilter.controls.some((control) => {
        return control.get('value')?.value == mall.value ? true : false;
      });
      if (alreadyExist) return;
      else {
        mallFilter.push(
          this.fb.group({
            label: mall.label,
            value: mall.value,
            checked: mall.checked,
          })
        );
      }
    });
  }

  public getMallListFormArray(): FormArray {
    return this.mallForm.get('mallListOptions') as FormArray;
  }

  public getMallFormArray(): FormArray {
    return this.filterForm.get('mall') as FormArray;
  }

  public getFilterFormValue(): any {
    let allFilterValue = this.filterForm?.getRawValue();

    if (allFilterValue?.orderByPriceDesc) {
      return allFilterValue;
    }

    if (allFilterValue?.mall) {
      const mallFilter = allFilterValue?.mall?.filter((mall) => {
        if (mall?.checked) return mall;
      });
      allFilterValue.mall = mallFilter.map((mall) => {
        return mall?.value;
      });
    }

    if (allFilterValue?.category) {
      allFilterValue.category = allFilterValue.category.map((category) => {
        return category?.id;
      });
    }

    if (allFilterValue?.typeDiscount) {
      allFilterValue.typeDiscount = allFilterValue?.typeDiscount.map((typeDiscount) => {
        return typeDiscount?.value;
      });
    }

    if (allFilterValue?.orderBy) {
      allFilterValue.orderBy = allFilterValue?.orderBy;
    }

    return allFilterValue;
  }

  public getFilterFormLabel(): any {
    let allFilterValue = this.filterForm?.getRawValue();

    if (allFilterValue?.mall) {
      const mallFilter = allFilterValue?.mall.filter((mall) => {
        if (mall?.checked) return mall;
      });

      allFilterValue.mall = mallFilter.map((mall) => {
        return mall?.label;
      });
    }

    if (allFilterValue?.category) {
      allFilterValue.category = allFilterValue?.category.map((category) => {
        return category?.name;
      });
    }

    if (allFilterValue?.typeDiscount) {
      allFilterValue.typeDiscount = allFilterValue?.typeDiscount.map((typeDiscount) => {
        return typeDiscount?.label;
      });
    }

    if (allFilterValue?.orderBy) {
      allFilterValue.orderBy = allFilterValue?.orderBy;
    }

    return allFilterValue;
  }

  public removeFilter(type, index, mall?): void {
    switch (type) {
      case 'mall': {
        const mallForm = this.getMallFormArray();
        mallForm.removeAt(index);
        const mallListForm = this.getMallListFormArray();
        mallListForm.controls.forEach((mallControl) => {
          if (mallControl.get('value')?.value === mall) mallControl.get('checked').setValue(false);
        });
        break;
      }
      default: {
        const array = this.filterForm.get(type) as FormArray;
        array.removeAt(index);
        break;
      }
    }
  }

  public showFilterMobileDrawer(): void {
    this.$drawer.create({
      nzContent: DrawerFiltersCouponComponent,
      nzTitle: null,
      nzFooter: null,
      nzClosable: false,
      nzContentParams: { filterHome: this.filterForm },
      nzBodyStyle: { background: '#f7f7f8' },
      nzWidth: '100%',
    });
  }
}
