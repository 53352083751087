import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-localation-request',
  templateUrl: './modal-localation-request.component.html',
  styleUrl: './modal-localation-request.component.scss',
})
export class ModalLocalationRequestComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
