<section *ngIf="!loading">
  <header>
    <img
      [ngClass]="isExpired || coupon.redeemed ? 'coupon-image inactive-image' : 'coupon-image'"
      [src]="coupon?.coupon_image || coupon?.seller?.image_url"
      onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
      alt="Imagem do cupom"
      [class.coupon-image-rescued]="coupon.rescued || coupon.redeemed"
    />

    <div class="discount-label">
      {{
        coupon.typeDiscount === 'PERCENT'
          ? coupon.value / 100 + '%'
          : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
      }}
      <br />
      OFF
      <span>Em produtos selecionados</span>
    </div>

    <img
      class="xmark"
      src="/assets/img/xmark.svg"
      alt="Ícone ilustrando um x para fechar o modal"
      (click)="modalRef.close()"
    />
  </header>

  <body>
    <div class="coupon-info">
      <div class="title">
        <h1>
          {{ coupon?.seller?.business_name || seller?.business_name }}
        </h1>

        <p>
          {{ coupon?.name }}
        </p>
      </div>

      <div class="coupon-details-container">
        <nz-row [nzGutter]="32">
          <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
            <div class="rules-container">
              <h2>Regras de uso</h2>

              <div class="rules-content">
                <div class="rules" *ngIf="coupon?.expiration_date">
                  <img src="/assets/img/calendar.svg" alt="Ícone de calendário" />
                  <p>Cupom válido até {{ coupon?.expiration_date | date : 'dd/MM/yyyy' }}</p>
                </div>

                <div class="rules" *ngIf="coupon?.user_usage_limit">
                  <img src="/assets/img/document.svg" alt="Ícone de identidade" />
                  <p>Válido para apenas {{ coupon?.user_usage_limit }} compra por CPF</p>
                </div>

                <div class="rules" *ngIf="coupon?.buy_value_min && coupon?.value">
                  <img src="/assets/img/tag.svg" alt="Ícone de etiqueta" />
                  <p>
                    Para compras acima de {{ coupon?.buy_value_min }}; Desconto de até
                    {{
                      coupon.typeDiscount === 'PERCENT'
                        ? coupon.value / 100 + '%'
                        : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
                    }}
                  </p>
                </div>

                <div class="rules">
                  <img src="/assets/img/warnning.svg" alt="Ícone de aviso" />
                  <p>Cupom sujeito a disponibilidade na loja</p>
                </div>
              </div>
            </div>
          </nz-col>
        </nz-row>
      </div>
    </div>

    <div class="coupon-activate-info-container">
      <div class="qr-code">
        <nz-qrcode [nzValue]="link" nzIcon="/assets/img/logo-gen-2024.jpg" nzLevel="H"></nz-qrcode>
      </div>

      <div class="coupon-activate-info-content">
        <h1>Como ativar desconto</h1>

        <ol>
          <li>Vá a loja</li>
          <li *ngIf="!isMobile">Acesse o cupom.gen.com.br através do celular</li>
          <li>Escaneie o código QR do caixa</li>
          <li>Aproveite seu desconto 🎉</li>
        </ol>
      </div>
    </div>

    @if(coupon?.sellerId === '6c1dee0d-8f79-45dc-baf0-5c6ab78802c0' || coupon?.sellerId ===
    'af2d5cb1-362f-420e-a2dc-7e663072b876' || coupon?.sellerId === '6749cd72-2e7f-4af3-8919-6361b8f6bc3e' ||
    coupon?.sellerId === 'cd807e12-c63a-4a2c-9109-39ca2c261d99') {
    <div class="button">
      <app-primary-button [block]="true" [disabled]="!nearlestMall?.length" (click)="goToScanner()"
        >Já estou na loja</app-primary-button
      >
    </div>
    }
  </body>
</section>

<div class="center m-t-2 m-b-2 p-b-2 p-t-2" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>
