import {
  RecaptchaSettings,
  RecaptchaV3Module,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';
import {
  NgxCurrencyConfig,
  NgxCurrencyInputMode,
  NgxCurrencyDirective,
  provideEnvironmentNgxCurrency,
} from 'ngx-currency';
import * as Sentry from '@sentry/angular-ivy';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { NgxMaskDirective, provideNgxMask, NgxMaskPipe } from 'ngx-mask';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { FromNowPipe } from './pipes/from-now.pipe';
import { DocumentPipe } from './pipes/document.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { ItemTypesPipe } from './pipes/item-types.pipe';
import { PixService } from './services/pix/pix.service';
import { GraphQLModule } from './graphql/graphql.module';
import { BannerTypePipe } from './pipes/banner-type.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { LeadService } from './services/lead/lead.service';
import { BnplService } from './services/bnpl/bnpl.service';
import { FeesService } from './services/fees/fees.service';
import { BankService } from './services/bank/bank.service';
import { LockerSizesPipe } from './pipes/locker-sizes.pipe';
import { TagService } from './services/item/tag/tag.service';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { ErrorService } from './services/error/error.service';
import { PlansService } from './services/plans/plans.service';
import { AlertService } from './services/alert/alert.service';
import { ItemsService } from './services/items/items.service';
import { StoreService } from './services/store/store.service';
import { UsersService } from './services/users/users.service';
import { UtilsService } from './services/utils/utils.service';
import { ColorContrastPipe } from './pipes/color-contrast.pipe';
import { SecondsToTimePipe } from './pipes/second-to-time.pipe';
import { PaymentMethodPipe } from './pipes/payment-method.pipe';
import { StripeService } from './services/stripe/stripe.service';
import { NumberToHoursPipe } from './pipes/number-to-hours.pipe';
import { TicketService } from './services/ticket/ticket.service';
import { OrdersService } from './services/orders/orders.service';
import { TokensService } from './services/tokens/tokens.service';
import { AntDesignModule } from './ant-design/ant-design.module';
import { BannerService } from './services/events/banner.service';
import { SystemService } from './services/system/system.service';
import { LockerService } from './services/locker/locker.service';
import { UppercaseFirstPipe } from './pipes/uppercase-first.pipe';
import { DeliveryStatusPipe } from './pipes/delivery-status.pipe';
import { DeliveryMethodPipe } from './pipes/delivery-method.pipe';
import { BannerLocationPipe } from './pipes/banner-location.pipe';
import { HidePartialCpfPipe } from './pipes/hide-partial-cpf.pipe';
import { ShoppingAcronymPipe } from './pipes/shopping-acronym.pipe';
import { HubspotService } from './services/hubspot/hubspot.service';
import { AddressService } from './services/address/address.service';
import { ReportsService } from './services/reports/reports.service';
import { SellersService } from './services/sellers/sellers.service';
import { ParkingService } from './services/parking/parking.service';
import { AlertComponent } from './components/alert/alert.component';
import { HidePartialCNPJPipe } from './pipes/hide-partial-cnpj.pipe';
import { AuthStoreService } from './services/auth/auth-store.service';
import { BalanceService } from './services/balances/balances.service';
import { PaymentsService } from './services/payments/payments.service';
import { PaymentFrequencyPlanPipe } from './pipes/frequency-plan.pipe';
import { InternalService } from './services/internal/internal.service';
import { WebhooksService } from './services/webhooks/webhooks.service';
import { CampaignService } from './services/campaign/campaign.service';
import { FooterComponent } from './components/footer/footer.component';
import { HidePartialEmailPipe } from './pipes/hide-partial-email.pipe';
import { ContactsService } from './services/contacts/contacts.service';
import { CustomerService } from './services/customer/customer.service';
import { FidelityService } from './services/fidelity/fidelity.service';
import { MixpanelService } from './services/mixpanel/mixpanel.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { IconsProviderModule } from './ant-design/icons-provider.module';
import { AuthService } from './services/auth/auth-business/auth.service';
import { BannersComponent } from './components/banners/banners.component';
import { CouponService } from './services/products/coupon/coupon.service';
import { BlacklistService } from './services/blacklist/blacklist.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DirectiveModule } from './directives/directive/directive.module';
import { LoadingComponent } from './components/loading/loading.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { CategoryService } from './services/item/category/category.service';
import { ProgressiveCountdownPipe } from './pipes/progressive-countdown.pipe';
import { WithdrawlsService } from './services/withdrawals/withdrawls.service';
import { CreditCardService } from './services/credit-card/credit-card.service';
import { ValidationDocuments } from './validators/validation-documents.service';
import { SharedMethodsService } from './services/internal/shared-methods.service';
import { StatusTagComponent } from './components/status-tag/status-tag.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { MainTableComponent } from './components/main-table/main-table.component';
import { ApplicationsService } from './services/applications/applications.service';
import { CancellmentsService } from './services/cancellments/cancellments.service';
import { ForgotPasswordService } from './services/external/forgot-password.service';
import { CustomAuthService } from './services/auth/custom-auth/custom-auth.service';
import { SentryErrorHandler } from './services/sentry/sentry-error-handler.service';
import { SegmentationService } from './services/segmentations/segmentations.service';
import { PaymentLinksService } from './services/payment-links/payment-links.service';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { NotificationsService } from './services/notifications/notifications.service';
import { SubscriptionsService } from './services/subscriptions/subscriptions.service';
import { PointOfSalesService } from './services/point-of-sales/point-of-sales.service';
import { GenericPageComponent } from './components/generic-page/generic-page.component';
import { PreviewPageComponent } from './components/preview-page/preview-page.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { BasketButtonComponent } from './components/basket-button/basket-button.component';
import { ModalSuccessComponent } from './components/modal-success/modal-success.component';
import { ModalLoadingComponent } from './components/modal-loading/modal-loading.component';
import { SimpleNavbarComponent } from './components/simple-navbar/simple-navbar.component';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { ModalGenericComponent } from './components/modal-generic/modal-generic.component';
import { CouponHeaderComponent } from './components/coupon-header/coupon-header.component';
import { CouponFooterComponent } from './components/coupon-footer/coupon-footer.component';
import { ModalFiltersComponent } from './components/modal-filters/modal-filters.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { MallCardInfoComponent } from './components/mall-card-info/mall-card-info.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CouponSidenavComponent } from './components/coupon-sidenav/coupon-sidenav.component';
import { SimpleSidenavComponent } from './components/simple-sidenav/simple-sidenav.component';
import { CouponDetailsComponent } from './components/coupon-details/coupon-details.component';
import { ModalLogoutComponent } from './components/modals/modal-logout/modal-logout.component';
import { ModalFilterComponent } from './components/modals/modal-filter/modal-filter.component';
import { UploadImageComponent } from './components/modals/upload-image/upload-image.component';
import { AddEditAppComponent } from './components/drawers/add-edit-app/add-edit-app.component';
import { UserDetailsComponent } from './components/drawers/user-details/user-details.component';
import { SellersEditComponent } from './components/drawers/sellers-edit/sellers-edit.component';
import { ModalPixErrorComponent } from './components/modal-pix-error/modal-pix-error.component';
import { FilterMenuComponent } from './components/sub-header/filter-menu/filter-menu.component';
import { QuantityButtonComponent } from './components/quantity-button/quantity-button.component';
import { ModalGenBoxComponent } from './components/modals/modal-gen-box/modal-gen-box.component';
import { SingletonSecureStorageService } from './services/utils/singleton-secure-storage.service';
import { ModalBlockedComponent } from './components/modals/modal-blocked/modal-blocked.component';
import { CvvBottomSheetComponent } from './components/cvv-bottom-sheet/cvv-bottom-sheet.component';
import { TableHeaderComponent } from './components/main-table/table-header/table-header.component';
import { ModalRegulationComponent } from './components/modal-regulation/modal-regulation.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { ModalWithdrawComponent } from './components/modals/modal-withdraw/modal-withdraw.component';
import { CouponSubHeaderComponent } from './components/coupon-sub-header/coupon-sub-header.component';
import { NavbarSubSellerComponent } from './components/navbar-sub-seller/navbar-sub-seller.component';
import { InputOpenSelectComponent } from './components/input-open-select/input-open-select.component';
import { ScannerAnimationComponent } from './components/scanner-animation/scanner-animation.component';
import { PreviewCouponAppComponent } from './components/preview-coupon-app/preview-coupon-app.component';
import { InputNumberRangeComponent } from './components/input-number-range/input-number-range.component';
import { ModalDeleteKeyComponent } from './components/modals/modal-delete-key/modal-delete-key.component';
import { DrawerSideMenuComponent } from './components/drawers/drawer-side-menu/drawer-side-menu.component';
import { ProductCardBasketComponent } from './components/product-card-basket/product-card-basket.component';
import { ItemEditableTableComponent } from './components/item-editable-table/item-editable-table.component';
import { NavigateAndFilterComponent } from './components/navigate-and-filter/navigate-and-filter.component';
import { ModalCreateUserComponent } from './components/modals/modal-create-user/modal-create-user.component';
import { ValidationPhoneComponent } from './components/security/validation-phone/validation-phone.component';
import { ConfirmationDeleteComponent } from './components/confirmation-delete/confirmation-delete.component';
import { ValidationEmailComponent } from './components/security/validation-email/validation-email.component';
import { ModalShowQrcodeComponent } from './components/modals/modal-show-qrcode/modal-show-qrcode.component';
import { ContactBottomSheetComponent } from './components/contact-bottom-sheet/contact-bottom-sheet.component';
import { ModalTicketWarningComponent } from './components/modal-ticket-warning/modal-ticket-warning.component';
import { ConfirmPhoneNumberComponent } from './components/confirm-phone-number/confirm-phone-number.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ModalInsertImageComponent } from './components/modals/modal-insert-image/modal-insert-image.component';
import { ModalImportItemsComponent } from './components/modals/modal-import-items/modal-import-items.component';
import { ModalPreviewPageComponent } from './components/modals/modal-preview-page/modal-preview-page.component';
import { ModalShareCouponComponent } from './components/modals/modal-share-coupon/modal-share-coupon.component';
import { ModalFindAddressComponent } from './components/modals/modal-find-address/modal-find-address.component';
import { ModalChangeEmailComponent } from './components/modals/modal-change-email/modal-change-email.component';
import { ModalChangePhoneComponent } from './components/modals/modal-change-phone/modal-change-phone.component';
import { DrawerCreateUserComponent } from './components/drawers/drawer-create-user/drawer-create-user.component';
import { CodeValidationInputComponent } from './components/code-validation-input/code-validation-input.component';
import { FilterCategoriesComponent } from './components/sub-header/filter-categories/filter-categories.component';
import { ModalContactStoreComponent } from './components/modals/modal-contact-store/modal-contact-store.component';
import { ModalChooseSellerComponent } from './components/modals/modal-choose-seller/modal-choose-seller.component';
import { ModalFilterCouponComponent } from './components/modals/modal-filter-coupon/modal-filter-coupon.component';
import { CvvBottomSheetModalComponent } from './components/cvv-bottom-sheet-modal/cvv-bottom-sheet-modal.component';
import { DrawerShareCouponComponent } from './components/drawers/drawer-share-coupon/drawer-share-coupon.component';
import { ModalItemAssociateComponent } from './components/modals/modal-item-associate/modal-item-associate.component';
import { ModalFilterProductComponent } from './components/modals/modal-filter-product/modal-filter-product.component';
import { ModalRejectMessageComponent } from './components/modals/modal-reject-message/modal-reject-message.component';
import { ModalWarningDeleteComponent } from './components/modals/modal-warning-delete/modal-warning-delete.component';
import { ExpandedSubHeaderComponent } from './components/sub-header/expanded-sub-header/expanded-sub-header.component';
import { DrawerFilterCouponComponent } from './components/drawers/drawer-filter-coupon/drawer-filter-coupon.component';
import { DrawerChooseSellerComponent } from './components/drawers/drawer-choose-seller/drawer-choose-seller.component';
import { ModalChangePasswordComponent } from './components/modals/modal-change-password/modal-change-password.component';
import { ModalCreateCategoryComponent } from './components/modals/modal-create-category/modal-create-category.component';
import { CreateWebhooksModalComponent } from './components/modals/create-webhooks-modal/create-webhooks-modal.component';
import { DrawerFeesDetailsComponent } from './components/drawers/fees/drawer-fees-details/drawer-fees-details.component';
import { ModalIncorrectImageComponent } from './components/modals/modal-incorrect-image/modal-incorrect-image.component';
import { ModalUploadDocumentComponent } from './components/modals/modal-upload-document/modal-upload-document.component';
import { ModalFilterSynapsesComponent } from './components/modals/modal-filter-synapses/modal-filter-synapses.component';
import { ModalLeadOnboardingComponent } from './components/modals/modal-lead-onboarding/modal-lead-onboarding.component';
import { ModalDeleteCreditCardComponent } from './components/modal-delete-credit-card/modal-delete-credit-card.component';
import { MobileHeaderSubSellerComponent } from './components/mobile-header-sub-seller/mobile-header-sub-seller.component';
import { DrawerCreateCouponsComponent } from './components/drawers/drawer-create-coupons/drawer-create-coupons.component';
import { ModalNoValueQrCodeComponent } from './components/modals/modal-no-value-qr-code/modal-no-value-qr-code.component';
import { ModalImportInventoryComponent } from './components/modals/modal-import-inventory/modal-import-inventory.component';
import { ModalExportInventoryComponent } from './components/modals/modal-export-inventory/modal-export-inventory.component';
import { QrCodeSuccessComponent } from './components/modals/modal-no-value-qr-code/qr-code-success/qr-code-success.component';
import { QuantityButtonSecondaryComponent } from './components/quantity-button-secondary/quantity-button-secondary.component';
import { ModalAvaliationCouponComponent } from './components/modals/modal-avaliation-coupon/modal-avaliation-coupon.component';
import { DrawerCvvBottomSheetComponent } from './components/drawers/drawer-cvv-bottom-sheet/drawer-cvv-bottom-sheet.component';
import { DrawerRegisterPixKeyComponent } from './components/drawers/drawer-register-pix-key/drawer-register-pix-key.component';
import { ModalUploadChargebackComponent } from './components/modals/modal-upload-chargeback/modal-upload-chargeback.component';
import { DrawerPaymentsDetailsComponent } from './components/drawers/drawer-payments-details/drawer-payments-details.component';
import { ModalUpdatePriceTableComponent } from './components/modals/modal-update-price-table/modal-update-price-table.component';
import { QrCodeGenerateComponent } from './components/modals/modal-no-value-qr-code/qr-code-generate/qr-code-generate.component';
import { ModalWithdrawalRequestComponent } from './components/modals/modal-withdrawal-request/modal-withdrawal-request.component';
import { ModalCreateLockerOrderComponent } from './components/modals/modal-create-locker-order/modal-create-locker-order.component';
import { ModalBulkEditInventoryComponent } from './components/modals/modal-bulk-edit-inventory/modal-bulk-edit-inventory.component';
import { DrawerCustomersEditComponent } from './components/drawers/customers/drawer-customers-edit/drawer-customers-edit.component';
import { ModalConfirmationDeleteComponent } from './components/modals/modal-confirmation-delete/modal-confirmation-delete.component';
import { ModalWhatsappValidationComponent } from './components/modals/modal-whatsapp-validation/modal-whatsapp-validation.component';
import { ModalConfirmationUpdateComponent } from './components/modals/modal-confirmation-update/modal-confirmation-update.component';
import { DrawerCreateSimpleComponent } from './components/drawers/invoices/simple/drawer-create-simple/drawer-create-simple.component';
import { ModalTermsAndConditionsComponent } from './components/modals/modal-terms-and-conditions/modal-terms-and-conditions.component';
import { ModalConfirmDeleteStoreComponent } from './components/modals/modal-confirm-delete-store/modal-confirm-delete-store.component';
import { ListCategoryOrTypeCouponsComponent } from './components/list-category-or-type-coupons/list-category-or-type-coupons.component';
import { ModalSuccessRedeemCouponComponent } from './components/modals/modal-success-redeem-coupon/modal-success-redeem-coupon.component';
import { DrawerCustomerDetailsComponent } from './components/drawers/customers/drawer-customer-details/drawer-customer-details.component';
import { DrawerCreateBlacklistComponent } from './components/drawers/blacklist/drawer-create-blacklist/drawer-create-blacklist.component';
import { ModalCreatePixKeySuccessComponent } from './components/modals/modal-create-pix-key-success/modal-create-pix-key-success.component';
import { ModalValidateRescueCouponComponent } from './components/modals/modal-validate-rescue-coupon/modal-validate-rescue-coupon.component';
import { QrCodeConfirmationComponent } from './components/modals/modal-no-value-qr-code/qr-code-confirmation/qr-code-confirmation.component';
import { ModalPixQrCodeConfirmationComponent } from './components/modals/modal-pix-qr-code-confirmation/modal-pix-qr-code-confirmation.component';
import { ModalDeleteKeySuccessComponent } from './components/modals/modal-delete-key/modal-delete-key-success/modal-delete-key-success.component';
import { ModalConfirmationCancelOrderComponent } from './components/modals/modal-confirmation-cancel-order/modal-confirmation-cancel-order.component';
import { ModalConfirmationPaymentOrderComponent } from './components/modals/modal-confirmation-payment-order/modal-confirmation-payment-order.component';
import { ConfirmLeadPhoneNumberComponent } from './components/modals/modal-lead-onboarding/confirm-lead-phone-number/confirm-lead-phone-number.component';
import { ModalConfirmationCancelPaymentComponent } from './components/modals/modal-confirmation-cancel-payment/modal-confirmation-cancel-payment.component';
import { ModalConfirmationDeliveryOrderComponent } from './components/modals/modal-confirmation-delivery-order/modal-confirmation-delivery-order.component';
import { DrawerConfirmationDeliveryOrderComponent } from './components/drawers/drawer-confirmation-delivery-order/drawer-confirmation-delivery-order.component';
import { ModalViewCategoryAndTypeCouponComponent } from './components/modals/modal-view-category-and-type-coupon/modal-view-category-and-type-coupon.component';
import { ModalDeleteKeyConfirmationComponent } from './components/modals/modal-delete-key/modal-delete-key-confirmation/modal-delete-key-confirmation.component';
import { ExpandedFilterCategorysChildrenComponent } from './components/sub-header/expanded-filter-categorys-children/expanded-filter-categorys-children.component';
import { DrawerPendingCompanyDetailsComponent } from './components/drawers/pending-company/drawer-pending-company-details/drawer-pending-company-details.component';
import { ModalAssocCategoryAndTypeToCouponComponent } from './components/modals/modal-assoc-category-and-type-to-coupon/modal-assoc-category-and-type-to-coupon.component';
import { DrawerPendingCompanyCreateUserComponent } from './components/drawers/pending-company/drawer-pending-company-create-user/drawer-pending-company-create-user.component';
import { ExpandedFilterCategoriesSubchildrenComponent } from './components/sub-header/expanded-filter-categories-subchildren/expanded-filter-categories-subchildren.component';
import { ModalCreateOrUpdateCategoryOrTypeCouponComponent } from './components/modals/modal-create-or-update-category-or-type-coupon/modal-create-or-update-category-or-type-coupon.component';

import { environment } from '@gen/environments';
import { PromotionComponent } from './components/promotion/promotion.component';
import { HeaderMarketplaceComponent } from './components/header-marketplace/header-marketplace.component';
import { SearchHeaderMarketPlaceComponent } from './components/drawers/search-header-marketplace/search-header-marketplace.component';
import { MarketplaceFilterComponent } from './components/marketplace-filter/marketplace-filter.component';
import { ItemVariantService } from './services/item/itemVariant/item-variant.service';
import { ModalRegisterMarketplaceComponent } from './components/modals/modal-register-marketplace/modal-register-marketplace.component';
import { ModalLoginMarketplaceComponent } from './components/modals/modal-login-marketplace/modal-login-marketplace.component';
import { MarketplaceService } from './services/marketplace/marketplace.service';
import { HidePartialPhonePipe } from './pipes/hide-partial-phone.pipe';
import { DrawerProfileMenuMarketplaceComponent } from './components/drawers/profile-menu-marketplace/profile-menu-marketplace.component';
import { NewOnboardingService } from './services/onboarding/newOnbording.service';
import { AccountCreatedComponent } from './components/onboarding/account-created/account-created.component';
import { ModalCreateEditBoxOfficeSectionComponent } from './components/modals/modal-create-edit-box-office-section/modal-create-edit-box-office-section.component';
import { LoginComponent } from './components/login/login.component';
import { SigninValidationSelectComponent } from './components/login/signin-validation-select/signin-validation-select.component';
import { SigninPasswordComponent } from './components/login/signin-password/signin-password.component';
import { CreatePasswordComponent } from './components/login/create-password/create-password.component.';
import { SigninCodeVerifyComponent } from './components/login/signin-code-verify/signin-code-verify.component';
import { InAnalysisComponent } from './components/onboarding/in-analysis/in-analysis.component';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { ModalAlertInstallmentsComponent } from './components/modals/modal-alert-installments/modal-alert-installments.component';
import { WeekDaysPipe } from './pipes/week-days.pipe';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { QuillModule, provideQuillConfig } from 'ngx-quill';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import Counter from './counter';
import { UrlShortenerService } from './services/url-shortener/url-shortener.service';
import { ModalCouponDetailsComponent } from './components/modals/modal-coupon-details/modal-coupon-details.component';
import { ModalBrandDetailsComponent } from './components/modals/modal-brand-details/modal-brand-details.component';
import { ModalImportContactsComponent } from './components/modals/modal-import-contacts/modal-import-contacts.component';
import { ModalUseCouponComponent } from './components/modals/modal-use-coupon/modal-use-coupon.component';
import { DrawerScanOriatationCouponComponent } from './components/drawers/drawer-scan-oriatation-coupon/drawer-scan-oriatation-coupon.component';
import { ModalTypeStoreIdentifyComponent } from './components/modals/modal-type-store-identify/modal-type-store-identify.component';
import { ModalLocalationRequestComponent } from './components/modals/modal-localation-request/modal-localation-request.component';
import { DrawerFiltersCouponComponent } from './components/drawers/drawer-filters-coupon/drawer-filters-coupon.component';

const PIPES = [
  FromNowPipe,
  DocumentPipe,
  InitialsPipe,
  FileSizePipe,
  PluralizePipe,
  ItemTypesPipe,
  PrettyJsonPipe,
  BannerTypePipe,
  FirstNamePipe,
  CustomDatePipe,
  LockerSizesPipe,
  TruncateTextPipe,
  PaymentMethodPipe,
  SecondsToTimePipe,
  ColorContrastPipe,
  NumberToHoursPipe,
  UppercaseFirstPipe,
  BannerLocationPipe,
  HidePartialCpfPipe,
  DeliveryStatusPipe,
  DeliveryMethodPipe,
  HidePartialCNPJPipe,
  ShoppingAcronymPipe,
  HidePartialEmailPipe,
  HidePartialPhonePipe,
  ProgressiveCountdownPipe,
  PaymentFrequencyPlanPipe,
  WeekDaysPipe,
];
const DRAWERS = [
  DrawerSideMenuComponent,
  DrawerFiltersCouponComponent,
  CvvBottomSheetComponent,
  DrawerCreateUserComponent,
  DrawerShareCouponComponent,
  DrawerFeesDetailsComponent,
  ContactBottomSheetComponent,
  DrawerChooseSellerComponent,
  DrawerCreateSimpleComponent,
  DrawerFilterCouponComponent,
  DrawerCustomersEditComponent,
  DrawerCreateCouponsComponent,
  DrawerCvvBottomSheetComponent,
  DrawerRegisterPixKeyComponent,
  DrawerPaymentsDetailsComponent,
  DrawerCustomerDetailsComponent,
  DrawerCreateBlacklistComponent,
  DrawerPendingCompanyDetailsComponent,
  DrawerPendingCompanyCreateUserComponent,
  DrawerConfirmationDeliveryOrderComponent,
  DrawerProfileMenuMarketplaceComponent,
  DrawerScanOriatationCouponComponent,
];
const MODALS = [
  ModalErrorComponent,
  ModalBrandDetailsComponent,
  ModalTypeStoreIdentifyComponent,
  ModalUseCouponComponent,
  ModalLogoutComponent,
  ModalFilterComponent,
  ModalLogoutComponent,
  ModalGenBoxComponent,
  ModalLogoutComponent,
  ModalBlockedComponent,
  ModalLoginMarketplaceComponent,
  ModalRegisterMarketplaceComponent,
  ModalSuccessComponent,
  ModalCouponDetailsComponent,
  ModalFiltersComponent,
  ModalGenericComponent,
  ModalLoadingComponent,
  ModalPixErrorComponent,
  ModalWithdrawComponent,
  ModalDeleteKeyComponent,
  ModalRegulationComponent,
  ModalCreateUserComponent,
  ModalShowQrcodeComponent,
  ModalInsertImageComponent,
  ModalShareCouponComponent,
  ModalChangeEmailComponent,
  ModalImportItemsComponent,
  ModalFindAddressComponent,
  ModalChangePhoneComponent,
  ModalPreviewPageComponent,
  ModalChooseSellerComponent,
  ModalFilterCouponComponent,
  ModalItemAssociateComponent,
  ModalTicketWarningComponent,
  ModalNoValueQrCodeComponent,
  ModalRejectMessageComponent,
  ModalChangePasswordComponent,
  ModalFilterSynapsesComponent,
  ModalUploadDocumentComponent,
  ModalCreateCategoryComponent,
  CreateWebhooksModalComponent,
  ModalLeadOnboardingComponent,
  ModalIncorrectImageComponent,
  ModalImportContactsComponent,
  ModalExportInventoryComponent,
  ModalImportInventoryComponent,
  ModalUpdatePriceTableComponent,
  ModalAvaliationCouponComponent,
  ModalUploadChargebackComponent,
  ModalDeleteCreditCardComponent,
  ModalDeleteKeySuccessComponent,
  ModalWithdrawalRequestComponent,
  ModalCreateLockerOrderComponent,
  ModalBulkEditInventoryComponent,
  ModalTermsAndConditionsComponent,
  ModalConfirmationDeleteComponent,
  ModalConfirmationUpdateComponent,
  ModalWhatsappValidationComponent,
  ModalConfirmDeleteStoreComponent,
  ModalSuccessRedeemCouponComponent,
  ModalCreatePixKeySuccessComponent,
  ModalValidateRescueCouponComponent,
  ModalPixQrCodeConfirmationComponent,
  ModalDeleteKeyConfirmationComponent,
  ModalConfirmationCancelOrderComponent,
  ModalConfirmationPaymentOrderComponent,
  ModalConfirmationDeliveryOrderComponent,
  ModalConfirmationCancelPaymentComponent,
  ModalViewCategoryAndTypeCouponComponent,
  ModalAssocCategoryAndTypeToCouponComponent,
  ModalCreateOrUpdateCategoryOrTypeCouponComponent,
  DrawerCustomersEditComponent,
  ModalCreateEditBoxOfficeSectionComponent,
  ModalAlertInstallmentsComponent,
  ModalLocalationRequestComponent,
];
const customerLoginComponents = [
  LoginComponent,
  SigninValidationSelectComponent,
  SigninPasswordComponent,
  CreatePasswordComponent,
  SigninCodeVerifyComponent,
];
const BUTTONS = [
  DeleteButtonComponent,
  BasketButtonComponent,
  PrimaryButtonComponent,
  QuantityButtonComponent,
  SecondaryButtonComponent,
  QuantityButtonSecondaryComponent,
];
const SERVICES = [
  TagService,
  PixService,
  LeadService,
  BnplService,
  AuthService,
  FeesService,
  BankService,
  ErrorService,
  UsersService,
  AlertService,
  ItemsService,
  PlansService,
  UtilsService,
  StoreService,
  StripeService,
  OrdersService,
  SystemService,
  BannerService,
  TicketService,
  CouponService,
  TokensService,
  ReportsService,
  ParkingService,
  BalanceService,
  HubspotService,
  SellersService,
  AddressService,
  FidelityService,
  CampaignService,
  LockerService,
  MixpanelService,
  InternalService,
  CategoryService,
  ContactsService,
  PaymentsService,
  WebhooksService,
  CustomerService,
  BlacklistService,
  AuthStoreService,
  CreditCardService,
  WithdrawlsService,
  CustomAuthService,
  PointOfSalesService,
  UrlShortenerService,
  PaymentLinksService,
  CancellmentsService,
  ApplicationsService,
  SegmentationService,
  NotificationsService,
  SharedMethodsService,
  SubscriptionsService,
  ForgotPasswordService,
  ItemVariantService,
  MarketplaceService,
  SellersService,
  NewOnboardingService,
  SingletonSecureStorageService,
];

@NgModule({
  declarations: [
    ...PIPES,
    ...MODALS,
    ...DRAWERS,
    ...BUTTONS,
    customerLoginComponents,
    RichTextEditorComponent,
    BannersComponent,
    HeaderMarketplaceComponent,
    InputNumberRangeComponent,
    ConfirmLeadPhoneNumberComponent,
    InputOpenSelectComponent,
    InAnalysisComponent,
    HeaderComponent,
    FooterComponent,
    CouponFooterComponent,
    CouponHeaderComponent,
    CouponSidenavComponent,
    CouponSubHeaderComponent,
    CouponDetailsComponent,
    GenericPageComponent,
    CodeValidationInputComponent,
    SubHeaderComponent,
    SimpleHeaderComponent,
    ScannerAnimationComponent,
    MallCardInfoComponent,
    ConfirmationDeleteComponent,
    ExpandedSubHeaderComponent,
    FilterMenuComponent,
    FilterCategoriesComponent,
    NavbarComponent,
    SimpleNavbarComponent,
    NavbarSubSellerComponent,
    MobileHeaderSubSellerComponent,
    LoadingComponent,
    SidenavComponent,
    SimpleSidenavComponent,
    MainTableComponent,
    StatusTagComponent,
    ImageCropperComponent,
    PreviewPageComponent,
    PreviewCouponAppComponent,
    ListCategoryOrTypeCouponsComponent,
    AccountCreatedComponent,
    AddEditAppComponent,
    SearchHeaderMarketPlaceComponent,
    ProductCardComponent,
    ProductCardBasketComponent,
    UploadImageComponent,
    PrimaryButtonComponent,
    PrivacyPolicyComponent,
    QrCodeSuccessComponent,
    QrCodeGenerateComponent,
    ValidationEmailComponent,
    ValidationPhoneComponent,
    ItemEditableTableComponent,
    TermsAndConditionsComponent,
    QrCodeConfirmationComponent,
    UserDetailsComponent,
    SellersEditComponent,
    ConfirmPhoneNumberComponent,
    ExpandedFilterCategorysChildrenComponent,
    StepperComponent,
    CvvBottomSheetModalComponent,
    TableHeaderComponent,
    ModalFilterProductComponent,
    ExpandedFilterCategoriesSubchildrenComponent,
    NavigateAndFilterComponent,
    ModalContactStoreComponent,
    ModalWarningDeleteComponent,
    AlertComponent,
    ModalConfirmDeleteStoreComponent,
    ModalGenBoxComponent,
    PromotionComponent,
    MarketplaceFilterComponent,
  ],
  imports: [
    QuillModule.forRoot(),
    ZXingScannerModule,
    NgxMaskPipe,
    FormsModule,
    CommonModule,
    RouterModule,
    GraphQLModule,
    DragDropModule,
    AntDesignModule,
    DirectiveModule,
    NgxMaskDirective,
    HttpClientModule,
    RecaptchaV3Module,
    ImageCropperModule,
    ReactiveFormsModule,
    IconsProviderModule,
    NgxCurrencyDirective,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
  ],
  exports: [
    ...DRAWERS,
    ...PIPES,
    ...BUTTONS,
    ...MODALS,
    customerLoginComponents,
    QuillModule,
    ZXingScannerModule,
    FormsModule,
    NgxMaskPipe,
    GenericPageComponent,
    ConfirmationDeleteComponent,
    InputOpenSelectComponent,
    RichTextEditorComponent,
    BannersComponent,
    GraphQLModule,
    InAnalysisComponent,
    DragDropModule,
    DirectiveModule,
    NavbarComponent,
    InputNumberRangeComponent,
    ConfirmLeadPhoneNumberComponent,
    SimpleNavbarComponent,
    NavbarSubSellerComponent,
    MobileHeaderSubSellerComponent,
    CodeValidationInputComponent,
    AntDesignModule,
    HeaderComponent,
    SimpleHeaderComponent,
    HeaderMarketplaceComponent,
    FooterComponent,
    CouponFooterComponent,
    CouponHeaderComponent,
    CouponSidenavComponent,
    CouponSubHeaderComponent,
    CouponDetailsComponent,
    NgxChartsModule,
    NgxMaskDirective,
    SubHeaderComponent,
    FilterMenuComponent,
    MallCardInfoComponent,
    ProductCardComponent,
    ProductCardBasketComponent,
    ScannerAnimationComponent,
    FilterCategoriesComponent,
    ExpandedSubHeaderComponent,
    HttpClientModule,
    SidenavComponent,
    LoadingComponent,
    StatusTagComponent,
    NgxCurrencyDirective,
    PreviewPageComponent,
    SimpleSidenavComponent,
    PreviewCouponAppComponent,
    ListCategoryOrTypeCouponsComponent,
    MainTableComponent,
    AccountCreatedComponent,
    ReactiveFormsModule,
    AddEditAppComponent,
    SearchHeaderMarketPlaceComponent,
    IconsProviderModule,
    ImageCropperComponent,
    UploadImageComponent,
    QrCodeSuccessComponent,
    PrivacyPolicyComponent,
    QrCodeGenerateComponent,
    AngularFireStorageModule,
    ValidationPhoneComponent,
    ValidationEmailComponent,
    ItemEditableTableComponent,
    QrCodeConfirmationComponent,
    TermsAndConditionsComponent,
    UserDetailsComponent,
    SellersEditComponent,
    ConfirmPhoneNumberComponent,
    ExpandedFilterCategorysChildrenComponent,
    StepperComponent,
    CvvBottomSheetModalComponent,
    TableHeaderComponent,
    ModalFilterProductComponent,
    NavigateAndFilterComponent,
    ModalWarningDeleteComponent,
    AlertComponent,
    PromotionComponent,
    MarketplaceFilterComponent,
  ],
  providers: [
    ...PIPES,
    ...SERVICES,
    SubHeaderComponent,
    FilterMenuComponent,
    ValidationDocuments,
    ExpandedSubHeaderComponent,
    provideEnvironmentNgxCurrency({
      align: '',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 2,
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    } as Partial<NgxCurrencyConfig>),
    { provide: REGION, useValue: 'southamerica-east1' },
    [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_KEY }],
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.RECAPTCHA_V3_KEY } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt',
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: SingletonSecureStorageService) => () => ds.init(),
      deps: [SingletonSecureStorageService, Sentry.TraceService],
      multi: true,
    },
    provideQuillConfig({
      customModules: [
        {
          implementation: Counter,
          path: 'modules/counter',
        },
      ],
      customOptions: [
        {
          import: 'formats/font',
          whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace'],
        },
      ],
    }),
    provideNgxMask(),
    CurrencyPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
