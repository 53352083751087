import { gql } from 'apollo-angular';

const CAMPAIGNS_QUERY = gql`
  query Campaigns($filters: FilterCampaignParamsInput, $pagination: PaginationParams) {
    campaigns(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        hasSms
        hasEmail
        hasWhatsapp
        title
        startDate
        endDate
        sendDate
        status
        segmentations {
          id
          name
          description
          contacts {
            id
            fullName
          }
        }
        seller {
          id
          cnpj
          business_name
        }
      }
    }
  }
`;

const CAMPAIGN_QUERY = gql`
  query Campaign($id: String!) {
    campaign(id: $id) {
      id
      title
      sellerId
      seller {
        id
        cnpj
        business_name
      }
      hasEmail
      hasSms
      hasWhatsapp
      sms {
        id
        link
        bodyMessage
        campaignId
      }
      email {
        id
        campaignId
        subjectEmail
        titleMessage
        bodyMessage
        templateSendgridId
        linkAction
        actionName
        imageUrl
      }
      whatsapp {
        bodyMessage
        whatsappPhoneNumber {
          id
          status
          phoneNumber
        }
        whatsappTemplate {
          status
          template
          id
        }
      }
      segmentations {
        id
        name
        description
        contacts {
          id
          fullName
          created_at
          updated_at
          cpf
          email
          phoneNumber
          isLegalEntity
          status
        }
      }
      customName
      sendDate
      sendMessages
      startDate
      endDate
      status
    }
  }
`;

const WHATSAPP_PHONE_NUMBERS_QUERY = gql`
  query WhatsappPhoneNumbers($filters: FilterWhatsappPhoneNumberParamsInput, $pagination: PaginationParams) {
    whatsappPhoneNumbers(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        phoneNumber
        status
      }
    }
  }
`;

const WHATSAPP_TEMPLATES_QUERY = gql`
  query WhatsappTemplates($filters: FilterWhatsappTemplateParamsInput, $pagination: PaginationParams) {
    whatsappTemplates(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        template
        status
      }
    }
  }
`;

const WEBHOOK_LOGS_REPORT_QUERY = gql`
  query GenerateWebhookLogsReport($sellerId: String!, $campaignId: String) {
    generateWebhookLogsReport(sellerId: $sellerId, campaignId: $campaignId) {
      totalSms
      totalSent
      totalRead
      totalEmail
      totalWhatsapp
      totalErrors
      totalClients
      totalNewClients
      totalOldClients
      chartData {
        name
        value {
          errorCount
          readCount
          sentCount
        }
      }
    }
  }
`;

const WEBHOOK_LOGS_QUERY = gql`
  query WebhookLogs($filters: FilterWhatsappTemplateParamsInput, $pagination: PaginationParams) {
    webhookLogs(filters: $filters, pagination: $pagination) {
      pageSize
      page
      totalPages
      total
      results {
        id
        template
        status
      }
    }
  }
`;

export {
  WEBHOOK_LOGS_REPORT_QUERY,
  WEBHOOK_LOGS_QUERY,
  CAMPAIGNS_QUERY,
  CAMPAIGN_QUERY,
  WHATSAPP_TEMPLATES_QUERY,
  WHATSAPP_PHONE_NUMBERS_QUERY,
};
