<section id="coupon-details">
  @if (loading) {
  <div class="center m-t-2 m-b-2 p-b-2 p-t-2">
    <nz-spin nzSize="large"></nz-spin>
  </div>
  }

  <nz-layout>
    @if (mode !== 'MY_COUPONS') {
    <nz-sider nzWidth="320px">
      <nz-card>
        <h3>Filtros</h3>
        <div class="clear-filter">
          <div class="d-flex">
            <h4>Filtros aplicados ({{
              getFilterFormLabel()?.mall?.length +
                getFilterFormLabel()?.category?.length +
                getFilterFormLabel()?.typeDiscount?.length || 0
            }})</h4>
            <span class="example-spacer"></span>
            <img src="/assets/icons/filter-xmark.svg" class="pointer-cursor" (click)="createFilterForm()" alt="" />
          </div>

          <ul class="filter-list">
            @if(getFilterFormLabel()?.mall?.length) { @for(mall of getFilterFormLabel()?.mall; track mall; let index =
            $index) {
            <nz-tag nzColor="lime" nzMode="closeable" class="m-t-1" (click)="removeFilter('mall', index, mall)">{{
              mall | shoppingAcronym
            }}</nz-tag>
            } } @if(getFilterFormLabel()?.category?.length){ @for(category of getFilterFormLabel()?.category; track
            category; let index = $index){
            <nz-tag nzColor="lime" nzMode="closeable" class="m-t-1" (click)="removeFilter('category', index)"
              >{{ category }}
            </nz-tag>
            } } @if(getFilterFormLabel()?.typeDiscount?.length) { @for(typeDiscount of
            getFilterFormLabel()?.typeDiscount; track typeDiscount; let index = $index){
            <nz-tag nzColor="lime" nzMode="closeable" class="m-t-1" (click)="removeFilter('typeDiscount', index)">{{
              typeDiscount
            }}</nz-tag>
            } }
          </ul>

          <hr class="hr-dashed" />

          <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
            <nz-collapse-panel nzHeader="Categorias" nzActive="true">
              <ul class="list-filters-type">
                @for(category of categories; track category.id) {
                <li (click)="changeCategoryFilter(category)">{{ category?.name }}</li>
                }@empty { Sem categorias para exibir }
              </ul>
            </nz-collapse-panel>
          </nz-collapse>

          <hr class="hr-dashed" />

          <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
            <nz-collapse-panel nzHeader="Tipos de cupons" nzActive="true">
              <ul class="list-filters-type">
                @for(coupon of typeCupons; track coupon) {
                <li (click)="chageTypeCouponFilter(coupon)">{{ coupon?.label }}</li>
                }@empty { Sem tipos de cupoms para exibir }
              </ul>
            </nz-collapse-panel>
          </nz-collapse>

          <hr class="hr-dashed" />

          <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
            <nz-collapse-panel nzHeader="Empreendimento" nzActive="true">
              <div *ngIf="mallForm?.get('mallListOptions')?.value">
                <form [formGroup]="mallForm">
                  <div formArrayName="mallListOptions">
                    <div *ngIf="getMallListFormArray()?.length > 0">
                      <div
                        *ngFor="let mallControl of getMallListFormArray()?.controls; let i = index"
                        [formGroupName]="i"
                      >
                        <label nz-checkbox formControlName="checked">
                          {{ mallControl.get('label').value | shoppingAcronym }}
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </nz-card>
    </nz-sider>
    }

    <nz-content>
      @if(!loading) {
      <ng-container>
        @if (filterData && filterData.length > 0) { @if ( !isMobile && reference === 'ACTIVE' && mode === 'MY_COUPONS')
        {

        <div class="search-content">
          <form [formGroup]="searchForm">
            <nz-form-item>
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large" [nzSuffix]="suffixTemplateSearch">
                  <input
                    nz-input
                    type="text"
                    formControlName="search"
                    placeholder="Pesquisar por cupons"
                    autocomplete="off"
                  />
                </nz-input-group>

                <ng-template #prefixIconSearch>
                  <img class="m-r-0-1" src="/assets/img/search-alt.svg" alt="Ícone de lupa" />
                </ng-template>

                <ng-template #suffixTemplateSearch>
                  <span *ngIf="loadingSearch" nz-icon nzType="loading" nzTheme="outline"></span>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>
          <div class="d-flex order-by">
            <div class="endBlock">
              @if (!isMobile) {
              <span class="order-by"
                >Ordenar por
                <a nz-dropdown [nzDropdownMenu]="menu">
                  {{ selectedOrderByItem }}
                  <span nz-icon nzType="down"></span> </a
              ></span>
              } @else {
              <span (click)="showFilterMobileDrawer()" class="filter"
                ><img src="/assets/img/filter.svg" alt="Filtro" /> Filtrar</span
              >
              }
            </div>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item (click)="onOrderByItemSelect('RELEVANCE')">Mais relevantes</li>
                <li nz-menu-item (click)="onOrderByItemSelect('PRICE_DESC')">Maior preço</li>
                <li nz-menu-item (click)="onOrderByItemSelect('PRICE_ASC')">Menor preço</li>
                <li nz-menu-item (click)="onOrderByItemSelect('DUE_DATE_DESC')">Próximos ao vencimento</li>
                <li nz-menu-item (click)="onOrderByItemSelect('AVALIATION_DESC')">Mais avaliados</li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </div>

        }
        <ng-container id="coupon-container">
          <section
            [ngStyle]="{
            padding: !isMobile ? '0 80px' : '0',
          }"
          >
            <div class="d-flex">
              @if (mode === 'MY_COUPONS' && reference !== 'ACTIVE') {
              <h2 class="title-list-h2">Cupons expirados ({{ inactiveCouponsAmount }})</h2>
              } @if (mode === 'MY_COUPONS' && reference === 'ACTIVE') {
              <h2 class="title-list-h2">Cupons salvos ({{ activeCouponsAmount }})</h2>
              } @if (mode === 'ALL') {
              <div class="between w-100">
                <h2 class="title-list-h2">Todos os cupons</h2>
                @if (!isMobile) {
                <span class="order-by"
                  >Ordenar por
                  <a nz-dropdown [nzDropdownMenu]="menu">
                    {{ selectedOrderByItem }}
                    <span nz-icon nzType="down"></span> </a
                ></span>
                } @else {
                <span (click)="showFilterMobileDrawer()" class="filter"
                  ><img src="/assets/img/filter.svg" alt="Filtro" /> Filtrar</span
                >
                }

                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item (click)="onOrderByItemSelect('RELEVANCE')">Mais relevantes</li>
                    <li nz-menu-item (click)="onOrderByItemSelect('PRICE_DESC')">Maior preço</li>
                    <li nz-menu-item (click)="onOrderByItemSelect('PRICE_ASC')">Menor preço</li>
                    <li nz-menu-item (click)="onOrderByItemSelect('DUE_DATE_DESC')">Próximos ao vencimento</li>
                    <li nz-menu-item (click)="onOrderByItemSelect('AVALIATION_DESC')">Mais avaliados</li>
                  </ul>
                </nz-dropdown-menu>
              </div>
              }
              <span class="example-spacer"></span>
            </div>
            <hr class="hr-dashed" />
            <nz-list nzGrid>
              <nz-row [nzGutter]="32">
                @for (coupon of filterData; track $index) {
                <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24" [nzXl]="12" [nzXXl]="12">
                  <nz-list-item class="margin-list">
                    @if (!coupon.loading) {
                    <ng-container>
                      <nz-card
                        class="coupon-card pointer-cursor"
                        (click)="useCoupon(coupon)"
                        [ngStyle]="{ opacity: reference === 'INACTIVE' ? '0.5' : '1' }"
                      >
                        <nz-row [nzGutter]="{ xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }" class="image-bottom">
                          <nz-col nzSpan="11">
                            <span class="effect-coupoun-left"></span>
                            <span class="effect-coupoun-left effect-coupoun-left__ghost"></span>

                            <div class="coupon-data">
                              <h3
                                class="coupon-title"
                                [ngStyle]="{
                              color: reference === 'INACTIVE' ? '#E1D8F9' : '#1b1b21',
                            }"
                              >
                                {{ coupon?.seller?.business_name || seller?.business_name }}
                                {{
                                  coupon?.seller?.business_name.length > 20 ||
                                  seller?.business_name.length > 20 ||
                                  coupon.description.length > 35
                                    ? coupon?.mall?.name.substring(0, 10) + '...'
                                    : coupon?.mall?.name
                                }}
                              </h3>

                              <div class="effect-group-cupon-middle">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                              <button class="fav-button" (click)="likeCoupon(coupon); $event.stopPropagation()">
                                <img
                                  [src]="
                                    coupon.isFavorite
                                      ? reference === 'INACTIVE'
                                        ? '/assets/img/purple-heart-coupon.svg'
                                        : '/assets/img/red-heart-coupon.svg'
                                      : '/assets/img/heart-coupon.svg'
                                  "
                                  alt="Icone de coração"
                                  class="icon-img"
                                />
                              </button>

                              <p
                                class="coupon-description"
                                [ngStyle]="{
                              color: reference === 'INACTIVE' ? '#E1D8F9' : '#626278',
                            }"
                              >
                                @if (coupon.description && coupon.description.length > 35) {
                                <span [innerHTML]="coupon.description.substring(0, 35) + '...'"> </span>
                                } @else {
                                <span [innerHTML]="coupon?.description || ''"> </span>
                                }
                              </p>

                              @if (coupon?.redeemed) {
                              <div class="coupon-redeemed">
                                <span>{{ coupon.redeemed }} + já utilizam</span>
                              </div>
                              }

                              <div class="d-flex">
                                <h3 class="coupon-rating no-margin">{{ coupon.couponRating }} / 5</h3>
                                <img class="p-l-1" src="../../assets/icons/star.svg" alt="Imagem de estrela" />
                              </div>
                            </div>
                          </nz-col>

                          <nz-col nzSpan="13" class="coupon-image">
                            <nz-row class="w-100">
                              <nz-col [nzXs]="14" [nzSm]="14" [nzMd]="18">
                                <img
                                  [ngClass]="reference === 'INACTIVE' ? 'coupon-image inactive-image' : 'coupon-image'"
                                  [src]="coupon?.coupon_image || coupon?.seller?.image_url"
                                  onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
                                  alt="Imagem do cupom"
                                />
                              </nz-col>
                              <nz-col [nzXs]="10" [nzSm]="10" [nzMd]="6">
                                <img
                                  class="logo-coupon"
                                  [src]="coupon?.seller?.image_url"
                                  onerror="this.onerror=null; this.src='/assets/img/default-coupon.svg'"
                                  alt="Imagem do cupom"
                                />
                                <div
                                  class="discount-label"
                                  [ngClass]="{
                                    'discount-label-active': reference === 'ACTIVE',
                                    'discount-label-inactive': reference === 'INACTIVE',
                                    'discount-label-default': reference !== 'ACTIVE' && reference !== 'INACTIVE'
                                  }"
                                >
                                  {{
                                    coupon.typeDiscount === 'PERCENT'
                                      ? coupon.value / 100 + '%'
                                      : 'R$' + (coupon.value / 100 | currency : ' ' : 'symbol' : '1.0-0')
                                  }}
                                  OFF
                                </div>
                              </nz-col>
                            </nz-row>
                          </nz-col>
                        </nz-row>
                      </nz-card>
                    </ng-container>
                    } @else {
                    <nz-card class="coupon-card" style="padding: 15px">
                      <nz-skeleton [nzActive]="true" [nzLoading]="true" [nzAvatar]="{ size: 'large' }"> </nz-skeleton>
                    </nz-card>
                    }
                  </nz-list-item>
                </nz-col>
                }
              </nz-row>
            </nz-list>
            <nz-row #loadMore class="w-100 m-b-1"></nz-row>
          </section>
        </ng-container>
        } @else {
        <ng-template #noData>
          <section class="m-t-2">
            @if (mode !== 'MY_COUPONS') {
            <nz-row class="m-b-1 center">
              <nz-col nzSpan="24">
                <span class="no-data-text">Essa sessão está vazia.</span>
              </nz-col>
            </nz-row>
            } @if (mode !== 'STORE') {
            <nz-row class="m-b-1 center">
              <nz-col nzSpan="24">
                <span class="no-data-text">
                  {{
                    mode === 'MY_COUPONS'
                      ? 'Você ainda não curtiu ou resgatou nenhum cupom.'
                      : 'Mas não se preocupe. Você pode ver outras categorias.'
                  }}
                </span>
              </nz-col>
            </nz-row>
            }
          </section>

          @if (reference !== 'INACTIVE') {
          <nz-row class="center m-b-1">
            <nz-col nzSpan="24">
              <app-primary-button routerLink="/" class="center"> Ver cupons </app-primary-button>
            </nz-col>
          </nz-row>
          }
        </ng-template>
        }
      </ng-container>
      }
    </nz-content>
  </nz-layout>
</section>
