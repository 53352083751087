<section>
  <div>
    <div class="w-100 between">
      <h3>Filtros</h3>
      <img
        class="xmark"
        src="/assets/img/xmark.svg"
        alt="Ícone ilustrando um x para fechar o modal"
        (click)="$drawerRef.close()"
      />
    </div>

    @if (filterForm) {
    <div class="clear-filter m-t-1">
      <div class="d-flex">
        <h4>
          Filtros aplicados ({{
            getFilterFormLabel()?.mall?.length +
              getFilterFormLabel()?.category?.length +
              getFilterFormLabel()?.typeDiscount?.length || 0
          }})
        </h4>
        <span class="example-spacer"></span>
        <img src="/assets/icons/filter-xmark.svg" class="pointer-cursor" (click)="clearAllFilters()" alt="" />
      </div>

      <ul class="filter-list">
        @if(getFilterFormLabel()?.mall?.length) { @for(mall of getFilterFormLabel()?.mall; track mall; let index =
        $index) {
        <nz-tag nzColor="lime" nzMode="closeable" class="m-t-1" (click)="removeFilter('mall', index, mall)">{{
          mall | shoppingAcronym
        }}</nz-tag>
        } } @if(getFilterFormLabel()?.category?.length){ @for(category of getFilterFormLabel()?.category; track
        category; let index = $index){
        <nz-tag nzColor="lime" nzMode="closeable" class="m-t-1" (click)="removeFilter('category', index)"
          >{{ category }}
        </nz-tag>
        } } @if(getFilterFormLabel()?.typeDiscount?.length) { @for(typeDiscount of getFilterFormLabel()?.typeDiscount;
        track typeDiscount; let index = $index){
        <nz-tag nzColor="lime" nzMode="closeable" class="m-t-1" (click)="removeFilter('typeDiscount', index)">{{
          typeDiscount
        }}</nz-tag>
        } }
      </ul>

      <nz-divider></nz-divider>

      <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
        <nz-collapse-panel nzHeader="Categorias" nzActive="true">
          <ul class="list-filters-type">
            @for(category of categories; track category.id) {
            <li (click)="changeCategoryFilter(category)">{{ category?.name }}</li>
            }@empty { Sem categorias para exibir }
          </ul>
        </nz-collapse-panel>
      </nz-collapse>

      <nz-divider></nz-divider>

      <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
        <nz-collapse-panel nzHeader="Tipos de cupons" nzActive="true">
          <ul class="list-filters-type">
            @for(coupon of typeCupons; track coupon) {
            <li (click)="chageTypeCouponFilter(coupon)">{{ coupon?.label }}</li>
            }@empty { Sem tipos de cupoms para exibir }
          </ul>
        </nz-collapse-panel>
      </nz-collapse>

      <nz-divider></nz-divider>

      <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
        <nz-collapse-panel nzHeader="Empreendimento" nzActive="true">
          <div *ngIf="mallForm?.get('mallListOptions')?.value">
            <form [formGroup]="mallForm">
              <div formArrayName="mallListOptions">
                <div *ngIf="getMallListFormArray()?.length > 0">
                  <div *ngFor="let mallControl of getMallListFormArray()?.controls; let i = index" [formGroupName]="i">
                    <label nz-checkbox formControlName="checked">
                      {{ mallControl.get('label').value | shoppingAcronym }}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    }
  </div>
</section>
