import html2canvas from 'html2canvas';
import moment from 'moment';

export function leftPad(value: any, totalWidth: number, paddingChar?: any): string {
  const length = totalWidth - value.toString().length + 1;
  return Array(length).join(paddingChar || '0') + value;
}

export function shortenName(value: string): string {
  const spl = value ? value.split(' ') : '';

  return spl.length > 1 ? spl[0] + ' ' + spl[spl.length - 1] : value;
}

export function removeWhiteSpace(_value: string): string {
  if (!_value) {
    return '';
  }

  return _value.replace(/ /g, '');
}

export function removeSpecialCharacters(_value: string): string {
  if (!_value) {
    return '';
  }

  return _value.replace(/[^a-z0-9]/gi, '');
}

export function removeCharacters(_value: string): string {
  if (!_value) {
    return '';
  }

  return _value.replace(/[^\d]+/g, '');
}

export function ignoreNumbers(event: any): void {
  const keyCode = event.keyCode ? event.keyCode : event.wich;

  if (keyCode > 47 && keyCode < 58) {
    return event.preventDefault(true);
  }
}

export function ignoreSpecialCharacters(event: any): void {
  const reg = /[^a-z0-9]/gi;
  if (event.key.match(reg)) {
    return event.preventDefault(true);
  }
}

export function onlyNumbers(event: any): void {
  const keyCode = event.keyCode ? event.keyCode : event.wich;
  if (event.shiftKey) {
    return event.preventDefault(true);
  } else {
    if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
      return event.preventDefault(true);
    }
  }
}

export function ignoreHotkeys(event: any): void {
  if (event.shiftKey) {
    return event.preventDefault(true);
  }
}

export function getSmallModalWidth(): string {
  const width = window.innerWidth;

  if (width <= 1300) {
    return '50%';
  } else if (width <= 1600) {
    return '40%';
  } else {
    return '35%';
  }
}

export function getBigModalWidth(): string {
  const width = window.innerWidth;

  if (width <= 1300) {
    return '70%';
  } else if (width <= 1600) {
    return '60%';
  } else {
    return '55%';
  }
}

export function trimData(data: any): any {
  Object.keys(data).forEach((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));
  return data;
}

export function formatCNPJ(cnpj: string): string {
  return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') || '';
}

export function formatCPF(cpf: string): string {
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || '';
}

export function formatCEP(cep: string): string {
  return cep?.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3') || '';
}

export function formatPhoneNumber(cep: string): string {
  return cep?.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4') || '';
}

export function getDayOfWeek(date: Date | string): string | null {
  const daysOfWeek = [
    'Domingo',
    'Segunda - feira',
    'Terça - feira',
    'Quarta - feira',
    'Quinta - feira',
    'Sexta - feira',
    'Sábado',
  ];
  const fullDay = new Date(date);
  const dayOfWeek = fullDay.getDay();
  const today = new Date(Date.now());

  if (today === fullDay) {
    return 'Hoje';
  }

  if (isNaN(dayOfWeek)) {
    return null;
  }

  return daysOfWeek[dayOfWeek];
}

export function downloadImgFromHtml(element: HTMLElement, name?: string, type?: string): void {
  html2canvas(element).then((canvas) => {
    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let imgLink = document.createElement('a');
    imgLink.href = image;
    imgLink.download = `${name || 'image'}.${type || 'png'}`;
    imgLink.click();
  });
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      resolve(reader.result as string);
    };
    reader.onerror = (error): void => {
      reject(error);
    };
  });
}

export function convertImageUrlToBase64(url: string): Promise<string> {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export function authToBase64(auth: { email: string; password: string }): string {
  const toEncode = `${auth.email}:${auth.password}`;
  return btoa(toEncode);
}

export function base64ToFile(base64Data: string, filename: string): File {
  const arr = base64Data.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function isIOS(): boolean {
  const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || '';
  if (platform) {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(platform);
  }

  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true;
  }

  return false;
}

export function clearAndLoadTable(_array?: any[]): any[] {
  return _array?.map((item) => ({ ...item, checked: false }));
}

export function convertToUpperCase(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function getInitials(value: string): string {
  const nameSplit = value.split(' ');
  return `${nameSplit[0].charAt(0).toUpperCase()}${nameSplit[1]?.charAt(0).toUpperCase()}`;
}

export function getTemporaryRoute(): string | undefined {
  return /(^http|https):\/\/.+?(?=\/)/g.exec(window.location.href)?.[0];
}

export function sortDaysOfWeek(data: Array<any>): Array<any> {
  const sorter: { [key: string]: number } = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
    holiday: 8,
  };

  return data.sort((a, b) => {
    let day1 = a.weekDay.toLowerCase();
    let day2 = b.weekDay.toLowerCase();
    return sorter[day1] - sorter[day2];
  });
}

export function getTranslatedDays(weekDay: string): string {
  const days: any = {
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    holiday: 'Feriados',
  };

  return days[weekDay];
}

export function downloadJSONFile(data: any, filename: string): void {
  const jsonContent = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonContent], { type: 'application/json' });

  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = filename;

  downloadLink.click();
}

export function disabledHours(): number[] {
  const currentHour = moment().hour();
  return Array.from({ length: currentHour }, (_, i) => i);
}

export function disabledMinutes(selectedHour: number): number[] {
  const currentHour = moment().hour();
  if (selectedHour === currentHour) {
    const currentMinute = moment().minute();
    return Array.from({ length: currentMinute }, (_, i) => i);
  }
  return [];
}
