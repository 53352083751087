import { gql } from 'apollo-angular';

const CREATE_CAMPAIGNS_MUTATION = gql`
  mutation CreateCampaign($sellerId: String!, $params: CampaignInput!) {
    createCampaign(sellerId: $sellerId, params: $params) {
      id
    }
  }
`;

const UPDATE_CAMPAIGNS_MUTATION = gql`
  mutation UpdateCampaign($id: String!, $sellerId: String!, $params: CampaignInput!) {
    updateCampaign(id: $id, sellerId: $sellerId, params: $params) {
      id
    }
  }
`;

const DUPLICATE_CAMPAIGN_MUTATION = gql`
  mutation DuplicateCampaign($id: [String!]!) {
    duplicateCampaign(id: $id) {
      id
    }
  }
`;

const DELETE_CAMPAIGN_MUTATION = gql`
  mutation DeleteCampaign($id: String!) {
    deleteCampaign(id: $id) {
      id
    }
  }
`;

export { CREATE_CAMPAIGNS_MUTATION, UPDATE_CAMPAIGNS_MUTATION, DUPLICATE_CAMPAIGN_MUTATION, DELETE_CAMPAIGN_MUTATION };
